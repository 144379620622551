<template>
  <v-container>
    <Preloader overlay v-if="userLoading" />
    <div class="headline mb-6 mt-1">
      {{ $t('message.admin.create_user') }}
    </div>
    <UserForm
      @submit="createUser"
      @cancel="cancel"
    />
  </v-container>
</template>

<script>
import UserForm from '@components/Shared/Forms/UserForm.vue';
import Preloader from '@components/Shared/Preloader.vue';
import { mapGetters } from 'vuex';

export default {
  name: 'CreateUser',
  components: {
    UserForm,
    Preloader,
  },
  props: {},
  computed: {
    ...mapGetters({
      userLoading: 'user/isLoading',
    }),
  },
  methods: {
    async createUser(formData) {
      await this.$api.users.createUsers(formData);
      this.pushToUsers();
    },
    cancel() {
      this.pushToUsers();
    },
    pushToUsers() {
      this.$router.push('/admin/users');
    },
  },
};
</script>
